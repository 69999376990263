let user = document.getElementById('socket_identifier');
var receiverID = null;
if (user !== null){
    receiverID = parseInt(user.getAttribute('data-value'));
}

var pusher = new Pusher('bc2bb1da490f6f38abf3', {
    cluster: 'eu'
});

var channel = pusher.subscribe('notify');
if(receiverID !== null){
    channel.bind(receiverID, function(e) {
        if(e['type']==='notification'){
            sendNotification(e);
        }
        else{
            sendOrderNotification(e);
        }
    });
}



function getNotificationText(messageData) {
    var type = messageData['typeOfNotification'];
    var text = null;
    switch (type) {
        case 'order':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-solid fa-burger"></i></span>'+
                Translator.trans('New Order')+'</p>'+
                '<p>'+ Translator.trans('You have a new order from')+'<span class="blued"> ' +messageData['sender']+' </span>'+
                '</p>';
            break;
        case 'problem':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-solid fa-circle-xmark"></i></span>'+
                Translator.trans('Report a problem')+'</p>'+
                '<p>'+ Translator.trans('You have a problem report regarding a previous order from')+'<span class="blued"> ' +messageData['sender']+' </span>'+
                '</p>';
            break;
        case 'like_event':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-solid fa-thumbs-up"></i></span>'+
                Translator.trans('Like')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('Added a like to your event.')+
                '</p>';
            break;
        case 'like_avis':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-solid fa-thumbs-up"></i></span>'+
                Translator.trans('Like')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('Added a like to your avis.')+
                '</p>';
            break;
        case 'like_camion':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-solid fa-thumbs-up"></i></span>'+
                Translator.trans('Like!')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('Added a like and follow to your truck.')+
                '</p>';
            break;
        case 'new_answer':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa fa-plus"></i></span>'+
                Translator.trans('New answer added')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('Added a response to your previous rating.')+
                '</p>';
            break;
        case 'new_avis':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-solid fa-star"></i></span>'+
                Translator.trans('New avis added')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('had been added rating to your truck.')+
                '</p>';
            break;
        case 'PaymentFailed':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa fa-ban"></i></span>'+
                Translator.trans('Subscription renewal failed')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('had failed to renew his periodic subscription.')+
                '</p>';
            break;
        case 'new_Meal':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa fa-cutlery"></i></span>'+
                Translator.trans('New product added')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('had been added new product to his truck.')+
                '</p>';
            break;
        case 'new_Plan':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa fa-map-marker"></i></span>'+
                Translator.trans('New plan added')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('had been added new plan to his truck.')+
                '</p>';
            break;
        case 'new_gallery':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa-regular fa-image"></i></span>'+
                Translator.trans('New photos added')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('had been added new photos to his truck.')+
                '</p>';
            break;
        case 'new_event':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa fa-calendar"></i></span>'+
                Translator.trans('New event added')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('had been added new event to his truck.')+
                '</p>';
            break;
        case 'link':
            text=
                '<p class="font-weight-bold mb-0">'+
                '<span class="blued mr-1"><i class="fa fa-truck"></i></span>'+
                Translator.trans('Successful link')+'</p>'+
                '<p>'+'<span class="blued"> '+messageData['sender']+' </span>'+ Translator.trans('Your truck has been successfully linked to your account.')+
                '</p>';
            break;

    }
    return text;
}
function plusCountNotification() {
    let notificationCounter = document.getElementById('notificationCount');
    if($('#notificationCount').length >0){
        let notificationNumber = parseInt(notificationCounter.innerHTML);
        notificationNumber++;
        notificationCounter.innerText = notificationNumber.toString() ;
    }
    else{
        let firstNotificationCounter = document.getElementById('notificationDropdown');
        firstNotificationCounter.innerHTML =
            '<span id="notificationCount"  class="px-1">'+1+'</span>'
            +'<img src="/assets/images/icons/notification.png" class ="icon-visitor">'
        ;
    }

}

function sendNotification(messageData) {
    let notif = document.getElementById('new-notification');

    let link = messageData['link'];
    let notifiableId = messageData['notifiableId'];
    let notificationId = messageData['notificationId'];
    let senderUserImage = messageData['senderImage'];
    let notification_text = getNotificationText(messageData);
    let markAsSeenHref =  Routing.generate('notification_mark_as_seen'+'.'+document.documentElement.lang,{notifiable:notifiableId,notification:notificationId});

    let now = new Date().toLocaleTimeString('en-US', { hour12: false,
        hour: "numeric",
        minute: "numeric"});
    let image;
    let elemantA;
    let typeON = messageData['typeOfNotification'];
    if(typeON==='link'){
        elemantA = '<a href="'+link+'" class="list__item--link my-0" notifiable="'+notifiableId+'" notification="'+notificationId+'">';
        image='<img src="'+senderUserImage+'" alt="" class="user-image mx-2 mt-1">';
    }
    else if(typeON==='PaymentFailed'){
        elemantA = '<a href="'+link+'" class="list__item--link my-0" notifiable="'+notifiableId+'" notification="'+notificationId+'">';
        image='<img src="'+senderUserImage+'" alt="" class="user-image mx-2 mt-1">';
    }
    else {
        elemantA = '<a href="'+link+'" class="list__item--link my-0" notifiable="'+notifiableId+'" notification="'+notificationId+'">';
        image='<img src="'+senderUserImage+'" alt="" class="user-image mx-2 mt-1">';
    }
    let notification =
        '<section class="one-note">'+
        '<li class="list__item ">'+
        elemantA+
        image+
        '<span class="messages mx-2">'
        +notification_text+
        '</span>'+
        '</a>'+
        '<div class="row">'+
        '<div class="col btn-group">'+
        '<div class="col-6 col-md-6 ">'+
        '<div class="all-as-seen mark-as">'+
        '<a href="'+markAsSeenHref+'" class="ajax-notification action-secondary">'+'Mark as seen'+'</a>'+
        '</div>'+
        '</div>'+
        '<div class="col-6 col-md-2 offset-4">'+
        '<div class="row">'+
        '<div class=" mb-1 mt-0">'+
        '<b class=" all-as-seen  float-right">'+
        now+
        '</b>'+
        '</div>'+
        '</div>'+
        '</div>'+
        '</div>'+
        '</div>'+
        '</li>'+
        '</section>';

    notif.insertAdjacentHTML('afterbegin',notification);


    var btns = document.getElementsByClassName('ajax-notification');
    function markAsSeen(e) {
        var xhttp = new XMLHttpRequest();
        var element = e.target;
        xhttp.onreadystatechange = function () {
            // on success
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // mark notification as seen
                /*element.parentNode.classList+= ' seen';*/
                element.parentNode.parentNode.parentNode.parentNode.parentNode.classList+='seen';
                // remove button
                element.remove();
                // decrease notification count
                var notificationCounter = document.getElementById('notificationCount');
                var notificationNumber = parseInt(notificationCounter.innerHTML);
                notificationNumber--;
                var unseen_large_Counter = document.getElementById('unseen-large-number');
                var unseen_large_Number = parseInt(unseen_large_Counter.innerHTML);
                unseen_large_Number--;
                if (notificationNumber ===0){
                    notificationCounter.remove();
                }
                else {
                    notificationCounter.innerHTML = notificationNumber.toString();
                }
                unseen_large_Counter.innerHTML = unseen_large_Number.toString();
            }
        };
        xhttp.open("POST", element.toString(), true);
        xhttp.send();
    }
    for(var btn of btns){
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            markAsSeen(e);
        });
    }
    // for add code mark as visited after send new notification
    function markAsVisited(e) {
        const link = e.target;
        const notifiable_id = link.parentNode.parentNode.getAttribute('notifiable');
        const notification_id = link.parentNode.parentNode.getAttribute('notification');

        if (notification_id !==null && notifiable_id !== null){
            var url = Routing.generate('notification_mark_as_seen'+'.'+document.documentElement.lang,{notifiable:notifiable_id,notification:notification_id});
            $.ajax({
                url: url,
                async: true,
                type: "POST",
                success: function (response) {
                }
            });
        }
    }

    const links = document.getElementsByClassName('list__item--link');
    for(const link of links){
        link.addEventListener('click', function (e)
        {
            markAsVisited(e)
        });
    }
    // end mark as visited
    plusCountNotification();
}

function sendOrderNotification(messageData) {
    let notif = document.getElementById('new-orders');

    let link = messageData['link'];
    let name = messageData['name'];
    let date = messageData['date'];
    let montant = messageData['montant'];
    let time = messageData['time'];

    let notification =
            '<a href="'+link+'">' +
                '<div class="order-bloc"> ' +
                    '<div class="order-bloc-left">' +
                        '<span>'+ Translator.trans('Name')+' :</span> <b>'+name+'</b>'+
                        '<br>' +
                        '<span>'+ Translator.trans('Order time')+' :</span> <b>'+date+'</b> <br> ' +
                        '<span>'+ Translator.trans('Amount paid')+' :</span> <b class="blue-c">'+montant+' €</b>' +
                    '</div>' +
                    '<div class="order-bloc-right order-unreaded text-center flashed"> ' +
                        '<small class="mb-0">'+Translator.trans('Delivery time')+'</small>' +
                        ' <br> ' +
                        '<p>'+time+'</p>' +
                    '</div>' +
        '       </div>' +
            '</a>';

    if(notif){
        $('#no-order-fond').hide();
        notif.insertAdjacentHTML('afterbegin',notification);
    }
    else{
        let btn = document.getElementById('btn-order');
        btn.classList.add("flashed");
    }

}