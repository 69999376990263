/*----------
commande to add rout js withe expose
php bin/console fos:js-routing:dump --format=json --target=public/js/fos_js_routes.json
--------------
yarn encore dev --watch
--------------
ngrok http http://127.0.0.1:8000

-------------
JWT_KEY='aVerySecretKey' ADDR='127.0.0.1:3000' ALLOW_ANONYMOUS=1 CORS_ALLOWED_ORIGINS="http://127.0.0.1:8000" ./mercure/mercure

ps aux
kill -9 id

node -v : 12.22.9
npm -v: 6.14.15


 composer require stof/doctrine-extensions-bundle v1.3.0
--------*/



var $ = require('jquery');
window.$ = $;
window.jQuery = $;
/*if(screen.width <576){
    $('#carouselExampleIndicators').remove();
    $('#img-seller').remove();
}*/
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
//users visits

require('./router');

/*require('./lib/jquery-ui.min');*/
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-slide');
require('jquery-ui/ui/widgets/autocomplete');

/*require('bootstrap');*/
require('bootstrap/js/src/alert');
require('bootstrap/js/src/carousel');
require('bootstrap/js/src/collapse');
require('bootstrap/js/src/dropdown');
require('bootstrap/js/src/modal');
require('bootstrap/js/src/tooltip');

require('./lib/notification');
require('./lib/markAsVisited');
require('./lib/pusher.min');
require('./lib/notify_pusher');
require('./lib/cart');
/*require('./lib/cookieconsent');*/


//-----------React.js
/*import Translator from 'bazinga-translator';*/
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
$(document).ready(function() {
        $('#google-login').addClass('gbtn');
});

/*$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});*/
//message flash fide out
window.setTimeout(function() {
    $('.message-flash').toggle("slide", { direction: "right" }, 50);
}, 5000)


$(document).ready(function (){
    $('[data-toggle="tooltip"]').tooltip({
        container : 'body'
    })
})
//----------------------------------------------------------------------



$(window).scroll(function(){
    if($(window).scrollTop()<40){
        $('#top-header').show();
        $('header').css('top','40px');
    }
    else
    {
        $('#top-header').hide();
        $('header').css('top','0px');
    }
});

$("#app_user_registration_type").change(function(){
    if(this.value == 1){
        $("#user-pro").css('display','block')
        $("#user-user").css('display','none')
    }
    if(this.value == 2){
        $("#user-pro").css('display','none')
        $("#user-user").css('display','block')
    }

});

// Small menu
$('#menu-small').click(function() {
    if($('#menu').css('display')=='none')
    {
        $('#menu').show();
    }
    else
    {
        $('#menu').hide();
    }
});

/*

window.fbAsyncInit = function() {
    FB.init({
        appId      : '267832865612859',
        xfbml      : true,
        version    : 'v17.0'
    });
    FB.AppEvents.logPageView();
};

(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
*/



