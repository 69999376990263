/*---------- add sub quantité  function ----------*/
function incrementValue(e) {
    e.preventDefault();
    var fieldName = $(e.target).data('field');
    var parent = $(e.target).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

    if (!isNaN(currentVal)) {
        if(currentVal < 10){
            parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
        }
    } else {
        parent.find('input[name=' + fieldName + ']').val(1);
    }
}

function decrementValue(e) {
    e.preventDefault();
    var fieldName = $(e.target).data('field');
    var parent = $(e.target).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

    if (!isNaN(currentVal) && currentVal > 1) {
        parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
    } else {
        parent.find('input[name=' + fieldName + ']').val(1);
    }
}


$('.click-input-group').on('click', '.button-plus', function(e) {
    incrementValue(e);
    changTotalPrice(e);

});

$('.click-input-group').on('click', '.button-minus', function(e) {
    decrementValue(e);
    changTotalPrice(e);
});

/*------change price of meals---------*/
function changTotalPrice(e){
    e.preventDefault();
    var mealId = e.target.getAttribute('data-mealId');
    var price = parseFloat(e.target.getAttribute('data-price')).toFixed(2);
    var total_btn = document.getElementById('total-price-meal-'+mealId);
    var parent = $(e.target).closest('div');
    var fieldName = $(e.target).data('field');
    let quantity =  parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
    let totalPrice = quantity*price;
    total_btn.innerText = totalPrice.toFixed(2);
}
/*---------- end all of meals----------*/

///
///
///
///

/*--------menu price----------*/

// event listener for menu checkboxes 4 options
let i = 1, l = 5;
for(; i < l; ++i) {
    $('input.single-checkbox-'+i).click(function (e) {
        const rand = e.target.getAttribute('data-rand');
        let x = document.querySelector('input.checkbox-' + rand);
        let max = x.getAttribute('data-max');
        if (this.checked === true) {
            disableRest(rand, max)
            addToPrice(e)
        } else {
            enableRest(rand)
            removeFromPrice(e)
        }

    });
}

//disable the rest checkbox when max possible checked
function disableRest(rand,max){
    const all = document.getElementsByClassName('checkbox-'+rand);
    let checked = 0
    for (let i = 0; i < all.length; i++) {
        if (all[i].checked){
            checked+=1;
        }
    }
    if(checked === parseInt(max)){
        for (let i = 0; i < all.length; i++) {
            if(all[i].checked=== false){
                all[i].disabled = true;
            }
        }
    }
}

//enable the rest checkbox when max possible unchecked
function enableRest(rand){
    const all = document.getElementsByClassName('checkbox-'+rand);
    for (let i = 0; i < all.length; i++) {
        all[i].disabled = false;
    }
}

//add added price to menuPrice hidden and total
function addToPrice(e){
    var menuId = e.target.getAttribute('data-menuId');
    let hiddenInput =document.getElementById('menu-hidden-price-'+menuId);
    let menuPrice = parseFloat(hiddenInput.value);
    let addedPrice = parseFloat(e.target.getAttribute('data-priceadd'));
    let newMenuPrice = menuPrice + addedPrice;
    hiddenInput.value = newMenuPrice.toFixed(2);

    /*change total value*/
    let qId= document.getElementById('quantity-'+menuId);
    let quantity =  parseInt(qId.value, 10);
    let priceShow =document.getElementById('total-price-menu-'+menuId);
    let total = newMenuPrice*quantity;
    priceShow.innerText = total.toFixed(2);

}
//sub added price from menuPrice hidden and total
function removeFromPrice(e){
    var menuId = e.target.getAttribute('data-menuId');
    let hiddenInput =document.getElementById('menu-hidden-price-'+menuId);
    let menuPrice = parseFloat(hiddenInput.value);
    let addedPrice = parseFloat(e.target.getAttribute('data-priceadd'));
    let newMenuPrice = menuPrice - addedPrice;
    hiddenInput.value = newMenuPrice.toFixed(2);

    /*change total value*/
    let qId= document.getElementById('quantity-'+menuId);
    let quantity =  parseInt(qId.value, 10);
    let priceShow =document.getElementById('total-price-menu-'+menuId);
    let total = newMenuPrice*quantity;
    priceShow.innerText = total.toFixed(2);
}
//change total price when change quantity
function changTotalMenuPrice(e){
    e.preventDefault();
    var menuId = e.target.getAttribute('data-menuId');
    let hiddenInput =document.getElementById('menu-hidden-price-'+menuId);
    let menuPrice = parseFloat(hiddenInput.value);

    var priceShow = document.getElementById('total-price-menu-'+menuId);
    var parent = $(e.target).closest('div');
    var fieldName = $(e.target).data('field');
    let quantity =  parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
    let total = menuPrice*quantity;
    priceShow.innerText = total.toFixed(2);
}

/*----menu add sub---*/
$('.menu-input-group').on('click', '.button-plus', function(e) {
    incrementValue(e);
    changTotalMenuPrice(e)
});

$('.menu-input-group').on('click', '.button-minus', function(e) {
    decrementValue(e);
    changTotalMenuPrice(e)
});

$(document).ready(function () {
    $('#problem_otherProblem').hide();
    $('#other_problem').hide();
    $('#problem_problem').on('change', function (e) {
        if (this.value === 'other') {
            $('#problem_otherProblem').show();
            $('#other_problem').show();
        }
    });
});

